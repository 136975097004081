//font-size
$heading_fontsize22: 22px;
$heading_fontsize20: 20px;
$heading_fontsize18: 18px;
$heading_fontsize16: 16px;


$font_wgt400: 400;
$font_wgt600: 600;


$font_color: #000;




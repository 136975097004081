@font-face {
    font-family: 'P22 Mackinac Pro';
    src: url('../fonts/P22MackinacPro-Book.eot');
    src: url('../fonts/P22MackinacPro-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/P22MackinacPro-Book.woff2') format('woff2'),
        url('../fonts/P22MackinacPro-Book.woff') format('woff'),
        url('../fonts/P22MackinacPro-Book.ttf') format('truetype'),
        url('../fonts/P22MackinacPro-Book.svg#P22MackinacPro-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'P22 Mackinac Pro';
    src: url('../fonts/P22MackinacPro-Bold.eot');
    src: url('../fonts/P22MackinacPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/P22MackinacPro-Bold.woff2') format('woff2'),
        url('../fonts/P22MackinacPro-Bold.woff') format('woff'),
        url('../fonts/P22MackinacPro-Bold.ttf') format('truetype'),
        url('../fonts/P22MackinacPro-Bold.svg#P22MackinacPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'P22 Mackinac Pro';
    src: url('../fonts/P22MackinacPro-BoldItalic.eot');
    src: url('../fonts/P22MackinacPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/P22MackinacPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/P22MackinacPro-BoldItalic.woff') format('woff'),
        url('../fonts/P22MackinacPro-BoldItalic.ttf') format('truetype'),
        url('../fonts/P22MackinacPro-BoldItalic.svg#P22MackinacPro-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'P22 Mackinac Pro';
    src: url('../fonts/P22MackinacPro-BookItalic.eot');
    src: url('../fonts/P22MackinacPro-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/P22MackinacPro-BookItalic.woff2') format('woff2'),
        url('../fonts/P22MackinacPro-BookItalic.woff') format('woff'),
        url('../fonts/P22MackinacPro-BookItalic.ttf') format('truetype'),
        url('../fonts/P22MackinacPro-BookItalic.svg#P22MackinacPro-BookItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'P22 Mackinac Pro';
    src: url('../fonts/P22MackinacPro-MediumItalic.eot');
    src: url('../fonts/P22MackinacPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/P22MackinacPro-MediumItalic.woff2') format('woff2'),
        url('../fonts/P22MackinacPro-MediumItalic.woff') format('woff'),
        url('../fonts/P22MackinacPro-MediumItalic.ttf') format('truetype'),
        url('../fonts/P22MackinacPro-MediumItalic.svg#P22MackinacPro-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'P22 Mackinac Pro';
    src: url('../fonts/P22MackinacPro-ExtraBold.eot');
    src: url('../fonts/P22MackinacPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/P22MackinacPro-ExtraBold.woff2') format('woff2'),
        url('../fonts/P22MackinacPro-ExtraBold.woff') format('woff'),
        url('../fonts/P22MackinacPro-ExtraBold.ttf') format('truetype'),
        url('../fonts/P22MackinacPro-ExtraBold.svg#P22MackinacPro-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'P22 Mackinac Pro';
    src: url('../fonts/P22MackinacPro-Medium.eot');
    src: url('../fonts/P22MackinacPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/P22MackinacPro-Medium.woff2') format('woff2'),
        url('../fonts/P22MackinacPro-Medium.woff') format('woff'),
        url('../fonts/P22MackinacPro-Medium.ttf') format('truetype'),
        url('../fonts/P22MackinacPro-Medium.svg#P22MackinacPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
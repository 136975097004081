/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "./assets/scss/variables";
@import "./assets/scss/font-faces";

/*-- This is basic primeng theme setting for overall to handle surface color and scaling to have more control over the project. --*/
body {
    font-weight: 400;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background: #f5f5f5;
    color: var(--text-color);
    // font-family: 'P22 Mackinac Pro';
    font-family: "Lato", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/*-- This is basic primeng theme setting for overall to handle surface color and scaling to have more control over the project. --*/

*{
    font-size: 12px;
}


ol, ul {
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
    font-size: 14px;
}
td a{
    font-size: 12px;
}
input, button, select, optgroup, textarea{
    font-family: "Lato" !important;
}
h2{
    font-size: $heading_fontsize22;
    font-weight: $font_wgt600;
}
h3{
    font-size: $heading_fontsize20;
}
h4{
    font-size: $heading_fontsize18;
    color: var(--surface-900);
}
h5{
    font-size: $heading_fontsize16;
    font-weight: $font_wgt400;
}
h6{
    font-size: $heading_fontsize16;
    font-weight: $font_wgt600;
    color: var(--primary-color);
}

p{
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
label{
font-size: 12px;
font-style: normal;
font-weight: 400;
}
::placeholder {
    color: var(--surface-400);
  }
  
.font-weight-bold{
    font-weight: 700;
}
.font-weight-normal{
    font-weight: 400;
}
.icon-size{
    font-size: 18px;
}
.icon-size20{
    font-size: 20px;
}
.icon-size-pdf{
    font-size: 40px;
}
.linkgray-col{
    color: var(--surface-900);
}
.button-size{
    ::ng-deep .p-button-label {
    font-size: 14px;
     }
}
.fontsize16{
    font-size: 16px;
}
thead, tbody, tfoot, tr, td, th {
    border-color: revert-layer;
    border-style: revert-layer;
    border-width: revert-layer;
    border: 1px solid #b1b3b54d;
}

.p-datatable-striped td, th, tr, thead {
    border-color: revert-layer;
    border-style: revert-layer;
    border-width: revert-layer;
    border: none;
}
.expend-icon {
    background: rgba(252, 240, 186, 1);
    padding: 2px 2px 2px 4px;
    .expend-icon-size{
        font-size: 15px;
        font-weight: 600;    
    }
}

    thead, tbody, tfoot, tr, td, th {
        border: none;
    }
     .p-datatable .p-datatable-thead > tr > th {
      background-color: rgba(248, 245, 237, 1);
      border-bottom: solid 2px #fff;
      color: rgba(0, 0, 0, 1);
    }
     .p-datatable .p-datatable-tbody > tr {
     
    }
    table th:first-child{
        border-radius:10px 0 0 10px;
      }
      
      table th:last-child{
        border-radius:0 10px 10px 0;
      }
    .see-link{
      a {
        color: var(--primary-color);
      }
    }

.vendor-status{
thead, tbody, tfoot, tr, td, th {
    border: solid 1px var(--bs-border-color) !important;
}
}


button {
    border-radius: revert-layer;
}
.hdr-cal{
    min-height: calc(100vh - 120px);
}
.loginButton {
    ::ng-deep .p-button-label {
        font-weight: 500;
        font-size: 13px;
    }
}
.dropdown-wdthfull {
    .p-dropdown, .p-multiselect {
        width: 100%;
    }
}
.wdthfull {
    .p-component {
        width: 100%;
    }
}
.custom-container{
    margin: 0 36px;
}
.spinner-contr {
    height: 100%;
    background: #222;
    opacity: 0.5;
    width: 100%;
    z-index: 9999;
}
.spinner-contr-half {
    height: 100%;
    background: #222;
    opacity: 0.5;
    width: 50%;
    
}
.error-state-input{
    border: solid 1px var(--red-500);
}
.error-state-dropdown{
    .p-dropdown{
        border: solid 1px var(--red-500);
    }
}
.error-state-calender{
    .p-calendar{
        border: solid 1px var(--red-500);
    }
}
.error-state-button{
    .p-fileupload-choose{
        border: solid 1px var(--red-500);
    }
}



.position-fix {
    &.right-bar-scroll{
    padding-right: 1px;
    height: calc(100vh - 10vh);
    overflow: auto;
    position: fixed;
    margin-right: 30px;
    width: -webkit-fill-available;
    }
    &.right-bar-scroll-dashboard{
        padding-right: 1px;
        height: calc(100vh - 255px);
        overflow: auto;
        position: sticky;
        margin-right: 30px;
        width: 100%;
        }
}
.gy-50{
    height: 50px;
}
.custom-container {
    .vendor-heading{
        .p-accordion-header-link {
            color: var(--bs-heading-color);
            background: rgba(251, 248, 235, 1);
            span{
                font-size: 14px;  
            }
            }  
    }
}
.btn-wdth-full{
    .p-button-secondary{
        width: 100%;
    }
}
.aer-col{
    color: var(--primary-color);
}

.w-multiselect{
    .p-multiselect{
        width: 100%;
    }
}
::ng-deep.p-button{
    background-color: #000;
}
.position-fix {
    h2{
        font-size: 18px !important;
        color: var(--surface-900) !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-weight: 500 !important;
        line-height: 1.2 !important;
        border-bottom: none !important;
        padding: 0 !important;
    }
    h4{
      font-size: $heading_fontsize18 !important;
      color: var(--surface-900) !important;
  }
}
.sticky-name-hgt{
    height: 42px;
    padding: 0;
    margin: 0;
}
.sticky-name {
  position: fixed;
  z-index: 999;
  background: #f5f5f5;
  /* left: 40px; */
  width: 72%;
  height: 42px;
}
.sticky-name2 {
    position: fixed;
    z-index: 999;
    background: #f5f5f5;
    /* left: 40px; */
    width: 72%;
    height: 25px;
  }
.applicant-name{
    height: 30px;   
}

.sticky-name .col-lg-9 {
  min-height: 36px;
}
 
.vendor-light-header-bg{
    background-color: rgb(247, 242, 237);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
}
.nadel-light-header-bg{
    background-color: rgba(253, 252, 247, 1);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
}
.buttonbg-white{
    .p-button{
        background-color: #fff;
    }
}
 .p-datepicker table td.p-datepicker-today > span{
    background-color: #623b2029;
    color: #495057;
}
 .p-datepicker table td > span.p-highlight {
    color: #ffffff;
    background: #623b20;
}
.missingInfo{
    border: 1px solid var(--red-300);
    .p-dropdown {
        border: 1.5px solid var(--red-300);
    }  
    .p-calendar {
        border: 1px solid var(--red-300);
    }  
}
.p-inputwrapper.missingInfo {
    border: none !important;
}
.highlight-red {
    label{
        color: var(--red-600);
        font-weight: 500;
    }
    .p-inputtext {
        border: 1.5px solid  var(--red-600);
    }
    .p-dropdown {
        border: 1.5px solid var(--red-300);
        .p-inputtext {
            border: none;
        } 
    }    
}
.successful-btn{
    .p-button{
        background: var(--green-500) !important;
        border: solid 1px var(--green-500) !important;
    }
}
.p-dialog{
    .p-dialog-header{
        .p-dialog-title{
            font-size: 18px !important;
            font-weight: 600;
        }
    }
}
.p-datepicker td>span{
    font-weight: 500;
}
.p-datepicker-header {
    .p-datepicker-title {
        button {
            font-size: 14px;
        }
    }
}
.event-popup {
    .gra-bg{
        .gap-2{
            .event-title{
                color: #363C48;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
            }
        }
        p{
            font-size: 12px;
            margin: 0;
            color: #8692AA;
        }
    }
}
.tdy-eve-managment {
    .event-title{
        color: #363C48;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    }
}

.wdthfull {
    .p-component {
      width: 100%;
    }
  }
.error-state-fileUpload{
    .p-fileupload-buttonbar {
        .p-fileupload-choose {
            background-color: var(--red-600) !important;
            border: var(--red-900) solid 1px !important;
            padding: 1rem;
        } 
    }
} 
.cursor-pointer{
    cursor: pointer;
}
.info-icon {
    color: var(--primary-color) !important;
    margin-left: 5px;
    margin-top: 2px;
}
.p-datepicker{
    background-color:#fff;
    thead, tbody, tfoot, tr, td, th{
        border:solid 1px var(--bs-border-color) !important;
       
      }  
}
.calender-container {
    thead, tbody, tfoot, tr, td, th{
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        padding: 1.2rem;
      }
    .p-datepicker{
        border-radius: var(--bs-border-radius) !important;
        box-shadow: var(--bs-box-shadow-sm) !important;
    }
   .p-datepicker-header{
       background-color: rgba(237, 227, 218, 1);
       display: flex;
       /* width: 100%; */
       justify-content: center;
       border-radius: 5px;
       button {
        font-size: 17px;
        color: rgba(0, 0, 0, 1);
        font-weight: 600;
    }
       .p-datepicker-title{
           margin: 0;
           button {
            font-size: 17px;
            color: rgba(0, 0, 0, 1);
            font-weight: 600;
        }
       }
   } 
}
/*-- successful popup --*/

.signup-check-icon{
    h2{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    h3{
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 18px;
    }
    .pi{
        color: var(--green-500);
        font-size: 48px;
    }
    .bck-dashboard{
        background-color: rgba(239, 239, 239, 1);
        cursor: pointer;
        color: var(--surface-900);
        display: inline-block;
    }
    
}
.disable-field-bg{
    #disabled-input{
        opacity: 1;
        background: rgba(226, 226, 226, 1);
        color: #000;
        border: none;
    }
}
.brown-line {
    .p-divider-solid {
        &::before{
            border-top: solid 1px #623b20;
        }
    }
}
.p-overlaypanel{
    z-index: 9999 !important;
}
/*-- successful popup --*/

/*-- highlight input field --*/
 
.alert-color {
    /*-- For blinking label --*/    
    label{
        color: #000;
        font-weight: 500;
        color: var(--red-600);
        // animation: color-change 1s infinite;
    }   
    @keyframes color-change { 
        0% { color: #000; font-weight: 500}
        50% { color: var(--red-600); font-weight: 500;}
    }
    /*-- For blinking label --*/ 
     
    /*-- For blinking input --*/
    .alerts-border-input {
        border: 1px var(--red-300) solid;
        animation: blink 1s;
        animation-iteration-count: infinite;
        @keyframes blink { 50% { border: 1px solid var(--red-800) ; }  }
    }
    /*-- For blinking input --*/
     
    /*-- For blinking Drop Down --*/
    .alerts-border-dropdown {
        .p-dropdown{
          border: 1px var(--red-300) solid;
          animation: blink3 1s;
          animation-iteration-count: infinite;
          @keyframes blink3 { 50% { border: 1px solid var(--red-800) ; }  }
      } 
    }
    /*-- For blinking Drop Down --*/
    }



/*-- highlight input field --*/

@media only screen and (max-width: 767px) {

    h3{
        font-size: $heading_fontsize18;
    }
    h4{
        font-size: $heading_fontsize16;
    }
    h5{
        font-size: $heading_fontsize16;
        font-weight: $font_wgt400;
    }
}
@media only screen and (max-width:1024px) {
.overflow-auto-md{
    overflow: auto;
}
}

/*-- 4K Resolution --*/


@media screen and (max-width: 3840px) {
    
        .event-calender {
           p-calendar{
            display: inline-grid;
            text-align: center;
           }
        }
    }
/*-- 4K Resolution --*/


 ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    cursor: pointer;
    }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--surface-50); 
  }
   
  /* Handle */
 ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 1px solid rgb(238, 225, 212);
    background: rgb(238, 225, 212); 
  }
  
  /* Handle on hover */
   ::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
  }

  /*-- For blinking box border --*/
.table-border-box { 
 
    background-color: var(--red-100) ;
    animation: blink2 1s infinite;
    animation-iteration-count: infinite;
    @keyframes blink2 { 50% { background-color: 1px solid var(--red-200) ; }  }
   
    
 
     td{
        background-color: var(--red-100);
        animation: blink2 1s infinite;
        animation-iteration-count: infinite;
        @keyframes blink2 { 50% { background-color:var(--red-200) ; }  }
    }
}
   
  /*-- For blinking box border --*/

  .pending-date {
    background: #d7bfa8;
    padding: 12px;
    color: #495057;
}

.signup-check-icon {
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 18px;
  }

  .pi {
    color: var(--green-500);
    font-size: 48px;
  }

  .bck-dashboard {
    background-color: rgba(239, 239, 239, 1);
    cursor: pointer;
    color: var(--surface-900);
    display: inline-block;
  }
}
/*-- invite screens --*/
.invite-container-frame{
    height: 95vh;
   display: flex;
   justify-content: center;
   align-items: center;
}
.invite-container{
   background-color: #fff;
   border-radius: 10px;
  
}
.invite-right-panel{
    border-top-right-radius:10px;
    border-bottom-right-radius:10px; 
    border-left: solid 1px rgba(235, 230, 226, 1);
    h2{
        font-size: 16px;
    }
    p {
        font-size: 12px;
    }
}
.invite-left-panel{
    padding: 80px 30px;
    background-color: #FFFDF5;
    border-top-left-radius:10px;
    border-bottom-left-radius:10px; 
    img{
        width: 90%;
    }   
}

.invitesuccess-left-panel{
    border-top-left-radius:10px;
    border-bottom-left-radius:10px; 
    border-left: solid 1px rgba(235, 230, 226, 1);
    h2{
        font-size: 16px;
    }
    p {
        font-size: 12px;
    }
}
.invitesuccess-right-panel{
    padding: 80px 30px;
    background-color: #FFFDF5;
    border-top-right-radius:10px;
    border-bottom-right-radius:10px; 
    img{
        width: 90%;
    }   
}


.red-col {
    color: var(--red-600);
    font-size: 19px;
    line-height: 13px;
} 
.desclimer-text{
    font-size: 12px;
    font-weight: 400;
}
